// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-post-js": () => import("./../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tags-page-js": () => import("./../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-tag-posts-js": () => import("./../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-singe-series-single-post-page-js": () => import("./../src/templates/singe-series-single-post-page.js" /* webpackChunkName: "component---src-templates-singe-series-single-post-page-js" */),
  "component---src-templates-series-list-page-js": () => import("./../src/templates/series-list-page.js" /* webpackChunkName: "component---src-templates-series-list-page-js" */),
  "component---src-templates-post-list-js": () => import("./../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

