const React = require("react")
const { ThemeProvider } = require("styled-components")
const { DefaultTheme } = require("./src/styles/theme")


// gatsby-ssr和gatsby-browser一樣，需要export wrapRootElement
exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      {element}
    </ThemeProvider>
  )
}