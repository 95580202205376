/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require("./src/styles/index.scss")

// 程式碼highlight
// require("./src/styles/prism-material-light.css")
require("./src/styles/prism-base16-ateliersulphurpool.light.css")
// require("./src/styles/prism-duotone-sea.css")

export { wrapRootElement } from './wrapRootElement'

